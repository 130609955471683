import StartgroupName from "@/models/sporteventCalculation/StartgroupName";
import BaseService from "../BaseService";

class StartgroupNameService extends BaseService<StartgroupName> {
  public url: string;

  constructor(url = "sportevent-calculation/startgroupname/") {
    super(url, "");
    this.url = url;
  }
}
export default new StartgroupNameService();
