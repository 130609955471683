
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import StartgroupName from "../../models/sporteventCalculation/StartgroupName";
import StartgroupNameService from "../../services/sporteventCalculation/StartgroupNameService";

@Component({
  components: {
    Multiselect,
  },
})
export default class StartgroupNameComponent extends Vue {
  public startgroupName: StartgroupName = new StartgroupName();

  mounted(): void {
    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      StartgroupNameService.get(this.$route.params.id).then(
        (item: StartgroupName) => {
          this.startgroupName = item;
        }
      );
    }
  }

  save(): void {
    StartgroupNameService.save(this.startgroupName).then(
      (item: StartgroupName) => {
        ToastService.Success(i18n.tc("labels.saved"), item.name);
        if (item) {
          this.startgroupName = item;
          this.handleBack();
        }
      }
    );
  }

  handleBack(): void {
    this.$router.back();
  }
}
